@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500);

$primary: #20d3b0;
$secondary: #6C91C2;

$font-family-sans-serif: "Open Sans", -apple-system, system-ui, sans-serif !default;

@import "~bootstrap/scss/bootstrap";

body{
  color: $gray-100;
  background-color: #1b1b26;
  font-size: 1.25rem;
}

a {
  text-decoration: none;
}

.app {
  > header {
    margin-bottom: 2rem;

    > .logo {
      margin-bottom: 0;
    }

    > nav {
      .nav-link {
        color: $gray-100;
        padding: 0;

        &:hover {
          color: $primary;
        }

        + * {
          margin-left: 1rem;
        }
      }
    }
  }
}